

import { lazy } from 'react';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';

import { AppRoutesModel, appRoutes } from './core/appRoutes';
import { getStoredValue } from './core/hooks/useStorage';
import { accessLevel, checkPermission, hasAccess, selfAccess } from './core/permissionService';
import { accessLevelTypes } from './core/config/permissions';

const ProtectedLayout = lazy(() => import('./layout/ProtectedLayout'));
const Home = lazy(() => import('./pages/protected/Dashboard'));
const Account = lazy(() => import('./pages/protected/account/UserAccount'));

const BottlesList = lazy(() => import('./pages/protected/bottles/BottlesList'));
const BottleDetails = lazy(() => import('./pages/protected/bottles/BottleDetails'));

const ClientsList = lazy(() => import('./pages/protected/clients/ClientsList'));
const SelfClient = lazy(() => import('./pages/protected/clients/SelfClient'));

const Layout = lazy(() => import('./pages/protected/layout/Layout'));

const MachineDetails = lazy(() => import('./pages/protected/Machine/MachineDetails'));
const MachinesList = lazy(() => import('./pages/protected/Machine/MachinesList'));
const MachineGroups = lazy(() => import('./pages/protected/Machine/MachineGroups'));
const MachineUsers = lazy(() => import('./pages/protected/Machine/MachineUsers'));
const MachineGroupDetails = lazy(() => import('./pages/protected/Machine/MachineGroupDetails'));
const MachineBottles = lazy(() => import('./pages/protected/Machine/MachineBottles'));
const MachineBottleDetails = lazy(() => import('./pages/protected/Machine/MachineBottleDetails'));
const LoadsList = lazy(() => import('./pages/protected/load/LoadsList'));

const NotificationList = lazy(() => import('./pages/protected/notifications/NotificationList'));
const NotificationDetails = lazy(() => import('./pages/protected/notifications/NotificationDetails'));

const PermissionGroups = lazy(() => import('./pages/protected/userGroup/UserGroups'));
const Permissions = lazy(() => import('./pages/protected/userGroup/Permissions'));
const PermissionGroupDetails = lazy(() => import('./pages/protected/userGroup/UserGroupDetails'));
const UsersPermission = lazy(() => import('./pages/protected/userGroup/UsersPermission'));

const PriceList = lazy(() => import('./pages/protected/price/PriceList'));
const PriceDetails = lazy(() => import('./pages/protected/price/PriceDetails'));
const PenaltyParams = lazy(() => import('./pages/protected/price/PenaltyParams'));
const Penalties = lazy(() => import('./pages/protected/price/Penalties'));
const PenaltyDetails = lazy(() => import('./pages/protected/price/PenaltyDetails'));

const PaymentMethods = lazy(() => import('./pages/protected/configurations/PaymentMethods'));
const NewPaymentMethod = lazy(() => import('./pages/protected/configurations//NewPaymentMethod'));
const PaymentMethodDetails = lazy(() => import('./pages/protected/configurations//PaymentMethodDetails'));

const TransactionsList = lazy(() => import('./pages/protected/transactions/TransactionsList'));
const TransactionDetails = lazy(() => import('./pages/protected/transactions/TransactionDetails'));

const UsersList = lazy(() => import('./pages/protected/users/UsersList'));
const UserDetails = lazy(() => import('./pages/protected/users/UserDetails'));
const NewUser = lazy(() => import('./pages/protected/users/NewUser'));

const AuthLayout = lazy(() => import('./layout/AuthLayout'));
const Login = lazy(() => import('./pages/public/Login'));
const ForgotPassword = lazy(() => import('./pages/public/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/public/ResetPassword'));
const NotFoundPage = lazy(() => import('./pages/public/NotFoundPage'));

// Checking token stored in the browser's local storage.
const isLoggedIn = getStoredValue('token') ?? null;
// Retrieving paths from a single file, appRoutes, for efficient customisations in the future.
const getPaths = (key: string) => appRoutes.find((item: AppRoutesModel) => { return item.routeName === key})?.path;

const canVisit = (pathName: string): boolean => {
    const hasPermission: boolean = hasAccess(pathName);
    const isAuthorized: boolean = (isLoggedIn !== null && isLoggedIn !== '');

    return (hasPermission && isAuthorized)
}

const canEdit = (pathName: string): boolean => {
    const level: accessLevelTypes = accessLevel(pathName);

    return (level === 'full')
}

const router = createBrowserRouter([
    {
        element: <ProtectedLayout />,
        errorElement: <NotFoundPage />,
        children: [
            {
                index: true,
                path: getPaths('home'),
                element: canVisit('home') ? <Navigate to={getPaths('transactions')??''}  replace /> : <Navigate to={getPaths('login')??''}  replace />
                // element: canVisit('home') ? <Home /> : <Navigate to={getPaths('login')??''}  replace />
            },
            {
                index: true,
                path: getPaths('account'),
                element: canVisit('account') ? <Account /> : <Navigate to={getPaths('login')??''}  replace />
            },
            {
                path: getPaths('notifications'),
                element: canVisit('notifications') ? <Outlet /> : <Navigate to={getPaths('login')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('notifications'),
                        element: canVisit('notifications') ? <NotificationList /> : <Navigate to={getPaths('login')??''}  replace />   
                    },
                    {
                        path: getPaths('notificationDetails'),
                        element: canVisit('notifications') ? <NotificationDetails /> : <Navigate to={getPaths('login')??''}  replace />
                    }
                ]
            },
            {
                path: getPaths('clients'),
                element: canVisit('clients') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('clients'),
                        element: canVisit('clients') ? <ClientsList /> : <Navigate to={getPaths('home')??''}  replace />
                    },
                ]
            },
            {
                path: getPaths('selfClient'),
                element: selfAccess('clients') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('selfClient'),
                        element: selfAccess('clients') ? <SelfClient /> : <Navigate to={getPaths('home')??''}  replace />
                    },
                ]
            },
            {
                path: getPaths('users'),
                element: canVisit('users') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('users'),
                        element: canVisit('users') ? <UsersList /> : <Navigate to={getPaths('home')??''}  replace />
                    }
                ]
            },
            {
                path: getPaths('permissionGroups'),
                element: canVisit('permissions') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('permissionGroups'),
                        element: canVisit('permissions') ? <PermissionGroups /> : <Navigate to={getPaths('home')??''}  replace />
                    },
                    {
                        path: getPaths('permissions'),
                        element: canVisit('permissions') ? <Permissions /> : <Navigate to={getPaths('permissionGroups')??''}  replace />
                    },
                    {
                        path: getPaths('permissionGroupDetails'),
                        element: canEdit('permissions') ? <PermissionGroupDetails /> : <Navigate to={getPaths('permissionGroups')??''}  replace />
                    },
                    {
                        path: getPaths('usersPermission'),
                        element: canVisit('permissions') ? <UsersPermission /> : <Navigate to={getPaths('permissionGroups')??''}  replace />
                    }
                ]
            },
            {
                path: getPaths('transactions'),
                element: checkPermission('transactions') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('transactions'),
                        element: canVisit('transactions') ? <TransactionsList /> : <Navigate to={getPaths('home')??''}  replace />
                    },
                    {
                        path: getPaths('transactionDetails'),
                        element: canEdit('transactions') ? <TransactionDetails /> : <Navigate to={getPaths('transactions')??''}  replace />
                    }
                ]
            },
            {
                path: getPaths('layout'),
                element: canVisit('layout') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('layout'),
                        element: canVisit('layout') ? <Layout /> : <Navigate to={getPaths('home')??''}  replace />
                    },
                    // {
                    //     path: getPaths('layoutDetails'),
                    //     element: canEdit('layout') ? <LayoutDetails /> : <Navigate to={getPaths('layout')??''}  replace />
                    // }
                ]
            },
            {
                path: getPaths('machines'),
                element: canVisit('machines') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('machines'),
                        element: canVisit('machines') ? <MachinesList /> : <Navigate to={getPaths('home')??''}  replace />
                    },
                    {
                        path: getPaths('machineDetails'),
                        element: canVisit('machines') ? <MachineDetails /> : <Navigate to={getPaths('machines')??''}  replace />
                    },
                    // {
                    //     path: getPaths('newMachine'),
                    //     element: canEdit('machines') ? <NewMachine /> : <Navigate to={getPaths('machines')??''}  replace />
                    // },
                    {
                        path: getPaths('machineUsers'),
                        element: canVisit('machineUsers') ? <MachineUsers /> : <Navigate to={getPaths('machines')??''}  replace />
                    },
                    {
                        path: getPaths('loadsList'),
                        element: canVisit('loadsList') ? <LoadsList /> : <Navigate to={getPaths('machines')??''}  replace />
                    }
                ]
            },
            {
                path: getPaths('machineGroups'),
                element: canVisit('machineGroups') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('machineGroups'),
                        element: canVisit('machineGroups') ? <MachineGroups /> : <Navigate to={getPaths('home')??''}  replace />
                    },
                    {
                        path: getPaths('machineGroupDetails'),
                        element: canVisit('machineGroups') ? <Outlet /> : <Navigate to={getPaths('machineGroups')??''}  replace />,
                        children: [
                            {
                                index: true,
                                path: getPaths('machineGroupDetails'),
                                element: canVisit('machineGroups') ? <MachineGroupDetails /> : <Navigate to={getPaths('machineGroups') ?? ''} replace />
                            },
                            {
                                path: getPaths('groupBottles'),
                                element: canVisit('groupBottles') ? <Outlet /> : <Navigate to={getPaths('machineGroups')??''}  replace />,
                                children: [
                                    {
                                        index: true,
                                        path: getPaths('groupBottles'),
                                        element: canVisit('groupBottles') ? <MachineBottles /> : <Navigate to={getPaths('machineGroups') ?? ''} replace />
                                    },
                                    {
                                        path: getPaths('groupBottleDetails'),
                                        element: canEdit('groupBottles') ? <MachineBottleDetails /> : <Navigate to={getPaths('machineBottles')??''}  replace />
                                    }
                                ]
                            },
                        ]
                    },
                ]
            },
            {
                path: getPaths('bottlesList'),
                element: canVisit('bottlesList') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('bottlesList'),
                        element: canVisit('bottlesList') ? <BottlesList /> : <Navigate to={getPaths('home')??''}  replace />
                    },
                    {
                        path: getPaths('bottleDetails'),
                        element: canEdit('bottlesList') ? <BottleDetails /> : <Navigate to={getPaths('bottlesList')??''}  replace />
                    },
                    // {
                    //     path: getPaths('newBottle'),
                    //     element: canEdit('bottlesList') ? <NewBottle /> : <Navigate to={getPaths('bottlesList')??''}  replace />
                    // }
                ]
            },
            {
                path: getPaths('priceList'),
                element: canVisit('priceList') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('priceList'),
                        element: canVisit('priceList') ? <PriceList /> : <Navigate to={getPaths('home')??''}  replace />
                    },
                    {
                        path: getPaths('priceDetails'),
                        element: canEdit('priceList') ? <PriceDetails /> : <Navigate to={getPaths('priceList')??''}  replace />
                    },
                ]
            },
            {
                path: getPaths('penaltyParams'),
                element: canVisit('penaltyParam') ? <Outlet /> : <Navigate to={getPaths('home')??''}  replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('penaltyParams'),
                        element: canVisit('penaltyParam') ? <PenaltyParams /> : <Navigate to={getPaths('home')??''}  replace />,
                    }
                ]
            },
            {
                path: getPaths('penaltyList'),
                element: canVisit('penalty') ? <Outlet /> : <Navigate to={getPaths('home') ?? ''} replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('penaltyList'),
                        element: canVisit('penalty') ? <Penalties /> : <Navigate to={getPaths('home')??''}  replace />,
                    },
                    {
                        path: getPaths('penaltyDetails'),
                        element: canEdit('penalty') ? <PenaltyDetails /> : <Navigate to={getPaths('penaltyList')??''} replace />,
                    }
                ]
            },
            {
                path: getPaths('paymentMethods'),
                element: canVisit('paymentMethods') ? <Outlet /> : <Navigate to={getPaths('home') ?? ''} replace />,
                children: [
                    {
                        index: true,
                        path: getPaths('paymentMethods'),
                        element: canVisit('paymentMethods') ? <PaymentMethods /> : <Navigate to={getPaths('home')??''}  replace />,
                    },
                    // {
                    //     path: getPaths('paymentMethodDetails'),
                    //     element: canEdit('paymentMethods') ? <PaymentMethodDetails /> : <Navigate to={getPaths('paymentMethods')??''} replace />,
                    // },
                    // {
                    //     path: getPaths('newPaymentMethod'),
                    //     element: canEdit('paymentMethods') ? <NewPaymentMethod /> : <Navigate to={getPaths('paymentMethods')??''} replace />,
                    // }
                ]
            }
        ]
    },
    {
        path: getPaths('login'),
        element: <AuthLayout />,
        errorElement: <NotFoundPage />,
        children: [
            {
                index: true,
                path: getPaths('login'),
                element: <Login />
            },
            {
                path: getPaths('forgotPassword'),
                element: <ForgotPassword />
            },
            {
                path: getPaths('resetPassword'),
                element: <ResetPassword />
            }
        ]
    },
]);

export default router