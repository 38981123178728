
import logo from '../assets/media/logo/text-logo.png';
import symbolLogo from '../assets/media/logo/symbol-logo.png';

export default function LoaderComponent() {
    return (
        <div className='fixed w-full h-screen bg-black flex justify-center item-center'>
            <div className="m-auto">
                <img src={symbolLogo} alt="Loading Vending Robotics ..." className="animate-spin max-w-20 mx-auto"/>
                <img src={logo} alt="Loading Vending Robotics ..." className="mx-auto max-w-36 mt-4"/>

            </div>
        </div>
    )
}