
// Storage Identifiers
export enum storageIdentifiers  {
    theme = 'theme',
    sidebar = 'sidenav',
    token = 'acc_token',
    ref_token = 'ref_token',
    user = 'user',
    permissions = 'rules',
}

export const setLocalStorage = (key: string, value?: string) => {
    value && value !== undefined ? 
        window.localStorage.setItem(storageIdentifiers[key as keyof typeof storageIdentifiers], value??'') :
        window.localStorage.removeItem(storageIdentifiers[key as keyof typeof storageIdentifiers]);
    return
}

export const getStoredValue = (key: string) => {
    return window.localStorage.getItem(storageIdentifiers[key as keyof typeof storageIdentifiers]);
}

